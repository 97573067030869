import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    addFavorite: (state, { payload }) => {
      state.list.push(payload);
    },
    removeFavorite: (state, { payload }) => {
      state.list = state.list.filter(item => item.id !== payload.id);
    },
    resetFavorites: state => {
      state.list = [];
    },
  },
  extraReducers: builder => {},
});

export const { addFavorite, removeFavorite, resetFavorites } = favoritesSlice.actions;

export default favoritesSlice.reducer;
