import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { appSettingsSelectors } from 'state-management/appSettings';

import { authSelectors } from 'state-management/auth';

const PrivateRoute = ({ children, redirectTo = '/sign-in' }) => {
  const isLoggedIn = useSelector(authSelectors.selectAuthIsLoggedIn);

  const isFirstVisitMobileApp = useSelector(appSettingsSelectors.selectIsFirstVisitMobileApp);

  // if (isFirstVisitMobileApp) {
  if (isFirstVisitMobileApp && !isLoggedIn) {
    return <Navigate to="/welcome" replace />;
  }

  return isLoggedIn ? children : <Navigate to={redirectTo} replace />;
};

export default PrivateRoute;
