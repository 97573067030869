import { createRef, lazy } from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';

import Layout from 'components/Layout/Layout';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
// import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';

import { ModalProvider } from 'hooks/useShowModalAndHideScroll';
import { IS_MOBILE_DEVICE } from 'services/constants';

// auth
const SignUpPage = lazy(() => import('pages/SignUpPage/SignUpPage'));
const ConfirmYourEmailPage = lazy(() => import('pages/ConfirmYourEmailPage/ConfirmYourEmailPage'));
const SignInPage = lazy(() => import('pages/SignInPage/SignInPage'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage/ForgotPasswordPage'));
const ForgotPasswordLinkSentPage = lazy(() =>
  import('pages/ForgotPasswordLinkSentPage/ForgotPasswordLinkSentPage'),
);
const PasswordResetRetryLaterPage = lazy(() =>
  import('pages/PasswordResetRetryLaterPage/PasswordResetRetryLaterPage'),
);
const CreateNewPasswordPage = lazy(() =>
  import('pages/CreateNewPasswordPage/CreateNewPasswordPage'),
);
const ResetPasswordLinkExpiredPage = lazy(() =>
  import('pages/ResetPasswordLinkExpiredPage/ResetPasswordLinkExpiredPage'),
);
const VerificationEmailPage = lazy(() =>
  import('pages/VerificationEmailPage/VerificationEmailPage'),
);
const VerificationEmailLinkExpiredPage = lazy(() =>
  import('pages/VerificationEmailLinkExpiredPage/VerificationEmailLinkExpiredPage'),
);
const EmailConfirmationPendingPage = lazy(() =>
  import('pages/EmailConfirmationPendingPage/EmailConfirmationPendingPage'),
);
const EmailConfirmedPage = lazy(() => import('pages/EmailConfirmedPage/EmailConfirmedPage'));

const WelcomePage = lazy(() => import('pages/WelcomePage/WelcomePage'));
const SubscriptionPage = lazy(() => import('pages/SubscriptionPage/SubscriptionPage'));

// not subscribed
const SellingPage = lazy(() => import('pages/SellingPage/SellingPage'));

// main
const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const SettingsPage = lazy(() => import('pages/SettingsPage/SettingsPage'));
const SuggestProductPage = lazy(() => import('pages/SuggestProductPage/SuggestProductPage'));
const SearchPage = lazy(() => import('pages/SearchPage/SearchPage'));
const ResultsPage = lazy(() => import('pages/ResultsPage/ResultsPage'));
const ProductPage = lazy(() => import('pages/ProductPage/ProductPage'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage/NotFoundPage'));
const FavoritesPage = lazy(() => import('pages/FavoritesPage/FavoritesPage'));
const SkinProfilePage = lazy(() => import('pages/SkinProfilePage/SkinProfilePage'));
const AnalysePage = lazy(() => import('pages/AnalysePage/AnalysePage'));
const IngredientsSummaryPage = lazy(() =>
  import('pages/IngredientsSummaryPage/IngredientsSummaryPage'),
);
const ProductSearchPage = lazy(() => import('pages/ProductSearchPage/ProductSearchPage'));
const ScanProductMobilePage = lazy(() =>
  import('pages/ScanProductMobilePage/ScanProductMobilePage'),
);

//legal
const AboutPage = lazy(() => import('pages/AboutPage/AboutPage'));
const TermsOfServicePage = lazy(() => import('pages/TermsOfServicePage/TermsOfServicePage'));
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage/PrivacyPolicyPage'));

const mobileOnlyLoader = () => {
  if (!IS_MOBILE_DEVICE) {
    return redirect('/');
  }
  return null;
};

export const routes = [
  {
    path: '/sign-up',
    name: 'SignUp',
    element: (
      <PublicRoute>
        <SignUpPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/need-confirm-email',
    name: 'ConfirmYourEmail',
    element: (
      <PublicRoute>
        <ConfirmYourEmailPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    element: (
      <PublicRoute>
        <SignInPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    element: (
      <PublicRoute>
        <ForgotPasswordPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/forgot-password-link-sent',
    name: 'ForgotPasswordLinkSent',
    element: (
      <PublicRoute>
        <ForgotPasswordLinkSentPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/forgot-password-retry-later',
    name: 'PasswordResetRetryLater',
    element: (
      <PublicRoute>
        <PasswordResetRetryLaterPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/w/account/forgot/verify/:verificationToken',
    name: 'CreateNewPassword',
    element: (
      <PublicRoute>
        <CreateNewPasswordPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/reset-password-link-expired',
    name: 'ResetPasswordLinkExpired',
    element: (
      <PublicRoute>
        <ResetPasswordLinkExpiredPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/w/account/verify/:verificationToken',
    name: 'VerificationEmail',
    element: (
      <PublicRoute redirectTo="/email-confirmed">
        <VerificationEmailPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/email-confirmed',
    name: 'EmailConfirmed',
    element: (
      <PrivateRoute>
        <EmailConfirmedPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/verification-email-link-expired',
    name: 'VerificationEmailLinkExpired',
    element: (
      <PublicRoute>
        <VerificationEmailLinkExpiredPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/email-confirmation-pending',
    name: 'EmailConfirmationPending',
    element: (
      <PublicRoute>
        <EmailConfirmationPendingPage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    element: (
      <PrivateRoute>
        <TermsOfServicePage />
      </PrivateRoute>
    ),

    nodeRef: createRef(),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    element: (
      <PrivateRoute>
        <PrivacyPolicyPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/about',
    name: 'About',
    element: (
      <PrivateRoute>
        <AboutPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/subscription-details',
    name: 'Selling',
    element: (
      <PrivateRoute>
        <SellingPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/scan-a-skincare-product',
    name: 'ScanProductMobile',
    element: (
      <PrivateRoute>
        <ScanProductMobilePage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
    loader: mobileOnlyLoader,
  },

  {
    path: '/smart-filter',
    name: 'Search',
    element: (
      <PrivateRoute>
        <SearchPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/results',
    name: 'Results',
    element: (
      <PrivateRoute>
        <ResultsPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/product/:productId',
    name: 'Product',
    element: (
      <PrivateRoute>
        <ProductPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/favorites',
    name: 'Favorites',
    element: (
      <PrivateRoute>
        <FavoritesPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/skin-profile',
    name: 'SkinProfile',
    element: (
      <PrivateRoute>
        <SkinProfilePage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/analyse',
    name: 'Analyse',
    element: (
      <PrivateRoute>
        <AnalysePage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/ingredients-summary',
    name: 'IngredientsSummary',
    element: (
      <PrivateRoute>
        <IngredientsSummaryPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/product-search',
    name: 'ProductSearch',
    element: (
      <PrivateRoute>
        <ProductSearchPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/settings',
    name: 'Settings',
    element: (
      <PrivateRoute>
        <SettingsPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/suggest-product',
    name: 'SuggestProduct',
    element: (
      <PrivateRoute>
        <SuggestProductPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/',
    name: 'Home',
    element: (
      <PrivateRoute>
        <HomePage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },

  {
    path: '/*',
    name: 'NotFound',
    element: <NotFoundPage />,
    nodeRef: createRef(),
  },

  {
    path: '/welcome',
    name: 'Welcome',
    element: (
      <PublicRoute>
        <WelcomePage />
      </PublicRoute>
    ),
    nodeRef: createRef(),
  },
  {
    path: '/subscription',
    name: 'Subscription',
    element: (
      <PrivateRoute>
        <SubscriptionPage />
      </PrivateRoute>
    ),
    nodeRef: createRef(),
  },
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      // <ErrorBoundary>
      <ModalProvider>
        <Layout />
      </ModalProvider>
      // </ErrorBoundary>
    ),
    children: routes.map(route => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
      loader: route.loader,
    })),
  },
]);

/*
const commonRoutes = [];

//  Mobile-specific routes
const mobileRoutes = [];
const webRoutes = [];

export const routes = [...(IS_MOBILE_PLATFORM ? mobileRoutes : webRoutes), ...commonRoutes];


const combineLoaders = (...loaders) => {
  return async args => {
    for (let loader of loaders) {
      const result = await loader(args);

      // If loader returns a redirect or other result, abort and return it
      if (result) return result;
    }

    //If no loader returned a result, continue with normal rendering
    return null;
  };
};
*/
