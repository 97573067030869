import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'state-management/store';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import LoadingDisplay from 'components/LoadingDisplay/LoadingDisplay';
import './index.css';
import 'styles/common.scss';
import 'styles/animation.css';
import * as storage from 'services/localStorage';

// import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { EXPERIMENTS_LS_KEY } from 'services/constants';
import { router } from 'routes/AllRoutes';
import { PopupProvider } from 'hooks/usePopup';

// Activate experiments integration
const experiments = storage.get(EXPERIMENTS_LS_KEY);

if (!experiments) {
  storage.save(EXPERIMENTS_LS_KEY, window.sbe);
}

// if (process.env.REACT_APP_ENV === 'development') {
//   import('mocks/browser')
//     .then(({ worker }) => {
//       worker.start({
//         onUnhandledRequest: 'bypass',
//         quiet: false,
//       });
//     })
//     .catch(error => console.error('Error loading the worker:', error));
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<LoadingDisplay />} persistor={persistor}>
        <HelmetProvider>
          <PopupProvider>
            <RouterProvider router={router} />
          </PopupProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
