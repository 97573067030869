import { createSlice } from '@reduxjs/toolkit';
import {
  ACNE_ISSUES_CHECKBOX,
  ACNE_ISSUES_RADIO,
  AGING_SIGNS_CHECKBOX,
  AGING_SIGNS_RADIO,
  EYE_CONCERNS_CHECKBOX,
  EYE_CONCERNS_RADIO,
  SKIN_CONCERNS_CHECKBOX,
  SKIN_TYPE,
  SPECIFIC_INGREDIENTS_FOR_AVOID,
} from 'data/profile';
import { CONCERNS_LIST_TYPE_VARIANT_RADIO } from 'services/constants';

const initialState = {
  skinType: SKIN_TYPE,
  skinConcerns: {
    checkbox: SKIN_CONCERNS_CHECKBOX,
  },
  acneIssues: {
    radio: ACNE_ISSUES_RADIO,
    checkbox: ACNE_ISSUES_CHECKBOX,
  },
  agingSigns: {
    radio: AGING_SIGNS_RADIO,
    checkbox: AGING_SIGNS_CHECKBOX,
  },
  eyeCare: {
    radio: EYE_CONCERNS_RADIO,
    checkbox: EYE_CONCERNS_CHECKBOX,
  },
  avoidedIngredients: SPECIFIC_INGREDIENTS_FOR_AVOID,
};
const updateConcerns = (list, elementName, type) =>
  list[type].map(el => {
    if (el.name === elementName) {
      return {
        ...el,
        is_selected: type === CONCERNS_LIST_TYPE_VARIANT_RADIO ? true : !el.is_selected,
      };
    } else if (type === CONCERNS_LIST_TYPE_VARIANT_RADIO) {
      return { ...el, is_selected: false };
    } else {
      return el;
    }
  });

const skinProfileSlice = createSlice({
  name: 'skinProfile',
  initialState,
  reducers: {
    setSkinSettings: (state, { payload }) => {
      state.skinType = payload.skinType;
      state.skinConcerns = payload.skinConcerns;
      state.acneIssues = payload.acneIssues;
      state.agingSigns = payload.agingSigns;
      state.eyeCare = payload.eyeCare;
      state.avoidedIngredients = payload.avoidedIngredients;
    },
    updateSkinType: (state, { payload }) => {
      state.skinType = state.skinType.map(el => ({
        ...el,
        is_selected: el.name === payload,
      }));
    },
    updateSkinConcern: (state, { payload }) => {
      state.skinConcerns[payload.type] = updateConcerns(
        state.skinConcerns,
        payload.name,
        payload.type,
      );
    },
    updateAcneIssues: (state, { payload }) => {
      state.acneIssues[payload.type] = updateConcerns(state.acneIssues, payload.name, payload.type);
    },
    updateAgingSigns: (state, { payload }) => {
      state.agingSigns[payload.type] = updateConcerns(state.agingSigns, payload.name, payload.type);
    },
    updateEyeCare: (state, { payload }) => {
      state.eyeCare[payload.type] = updateConcerns(state.eyeCare, payload.name, payload.type);
    },
    updateAvoidedIngredients: (state, { payload }) => {
      let noneSelected = payload === 'None, I am ok with most ingredients';

      // Single iteration to update the selection state and determine if "None" should be reselected
      state.avoidedIngredients = state.avoidedIngredients.map(ingredient => {
        if (noneSelected) {
          return { ...ingredient, is_selected: ingredient.name === payload };
        } else if (ingredient.name === payload) {
          return { ...ingredient, is_selected: !ingredient.is_selected };
        } else if (ingredient.name === 'None, I am ok with most ingredients') {
          return { ...ingredient, is_selected: false };
        }
        return ingredient;
      });

      // Check if none of the ingredients are selected and reselect "None" if necessary
      if (!noneSelected && state.avoidedIngredients.every(ingredient => !ingredient.is_selected)) {
        state.avoidedIngredients = state.avoidedIngredients.map(ingredient =>
          ingredient.name === 'None, I am ok with most ingredients'
            ? { ...ingredient, is_selected: true }
            : ingredient,
        );
      }
    },
  },
});

export const {
  setSkinSettings,
  updateAcneIssues,
  updateAgingSigns,
  updateEyeCare,
  updateSkinConcern,
  updateSkinType,
  updateAvoidedIngredients,
} = skinProfileSlice.actions;

export default skinProfileSlice.reducer;
