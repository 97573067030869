import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAcceptTerms: window.xb !== undefined ? true : false,
  isShowHelperProductPopup: true,
  originListName: '',
  timesShowAddedToFavoriteNotification: 0,
  isFirstVisitMobileApp: true,
};

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setOriginListName: (state, { payload }) => {
      state.originListName = payload;
    },
    incrementTimesShowAddedToFavoriteNotification: state => {
      state.timesShowAddedToFavoriteNotification += 1;
    },
    closeHelperProductPopup: (state, _) => {
      state.isShowHelperProductPopup = false;
    },
    acceptTerms: (state, _) => {
      state.isAcceptTerms = true;
    },
    disableFirstVisitMobileAppFlag: (state, _) => {
      state.isFirstVisitMobileApp = false;
    },
  },
  extraReducers: builder => {},
});

export const {
  setOriginListName,
  closeHelperProductPopup,
  incrementTimesShowAddedToFavoriteNotification,
  acceptTerms,
  disableFirstVisitMobileAppFlag,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
