import { Helmet } from 'react-helmet-async';
import { routes } from 'routes/AllRoutes';
import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Capacitor } from '@capacitor/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import TabBar from 'components/common/TabBar/TabBar';
// import EntryBanner from 'components/EntryBanner/EntryBanner';
import LoadingDisplay from 'components/LoadingDisplay/LoadingDisplay';
import Footer from 'components/Footer/Footer';

import { isPathMatch } from 'services/utilities';
import {
  IS_MOBILE_PLATFORM,
  IS_WEB_PLATFORM,
  SIZE_URL_BAR,
  TIMEOUT_FOR_CSS_TRANSITION,
} from 'services/constants';
import { useIsAppleDevice } from 'hooks/useIsAppleDevice';
// import { appSettingsSelectors } from 'state-management/appSettings';
import { authSelectors } from 'state-management/auth';

import s from './Layout.module.scss';

const pagesPathNeedFooter = ['/', '/product-search', '/results', '/favorites', '/analyse'];

const pageWithTabBar = [
  '/',
  '/favorites',
  '/scan-a-skincare-product',
  '/results',
  '/settings',
  '/smart-filter',
  '/analyse',
  '/ingredients-summary',
  '/subscription-details',
];

// const pagesWithoutBanner = [
//   '/skin-profile',
//   '/privacy-policy',
//   '/terms-of-service',
//   '/scan-a-skincare-product',
//   '/welcome',
//   '/subscription',
//   '/settings',
//   '/suggest-product',
//   '/subscription-details',
// ];

const Layout = () => {
  const currentOutlet = useOutlet();
  const location = useLocation();
  // const isAcceptTerms = useSelector(appSettingsSelectors.selectIsAcceptTerms);
  const isLoggedIn = useSelector(authSelectors.selectAuthIsLoggedIn);

  const isAppleDevice = useIsAppleDevice();
  const nodeRef = useMemo(() => {
    for (const route of routes) {
      if (route.path === '/*') continue;
      if (isPathMatch(route.path, location.pathname)) {
        return route.nodeRef;
      }
    }
    return routes.find(route => route.path === '/*')?.nodeRef;
  }, [location.pathname]);

  const handleAnimationStart = useCallback(() => {
    // URL bar can take up to 70px.

    window.scroll({
      top: isAppleDevice ? -SIZE_URL_BAR : 0,
      left: 0,
    });
  }, [isAppleDevice]);

  useEffect(() => {
    // Disable the browser's automatic scroll recovery, which it does before the animation starts
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    const platform = Capacitor.getPlatform();

    if (platform === 'ios') {
      // iOS platform

      document.documentElement.style.setProperty('--safe-area-top', 'env(safe-area-inset-top)');
      document.documentElement.style.setProperty(
        '--safe-area-bottom',
        'env(safe-area-inset-bottom)',
      );
      document.documentElement.style.setProperty('--padding-top', '3px');
    } else if (platform === 'android') {
      // Android platform with approximate values
      document.documentElement.style.setProperty('--safe-area-top', '0px');
      document.documentElement.style.setProperty('--safe-area-bottom', '0px');
      // document.documentElement.style.setProperty('--safe-area-top', '24px');
      // document.documentElement.style.setProperty('--safe-area-bottom', '16px');
    } else if (platform === 'web') {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        // Android web
        // document.documentElement.style.setProperty('--safe-area-top', '24px');
        // document.documentElement.style.setProperty('--safe-area-bottom', '16px');
        document.documentElement.style.setProperty('--safe-area-top', '0px');
        document.documentElement.style.setProperty('--safe-area-bottom', '0px');
        document.documentElement.style.setProperty('--padding-bottom-tab-bar', '10px');
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // iOS web
        document.documentElement.style.setProperty('--safe-area-top', '0px');
        document.documentElement.style.setProperty(
          '--safe-area-bottom',
          'env(safe-area-inset-bottom)',
        );
      } else {
        // Default for other web platforms
        document.documentElement.style.setProperty('--safe-area-top', '0px');
        document.documentElement.style.setProperty('--safe-area-bottom', '0px');
      }
    } else {
      // Default for unknown platforms
      document.documentElement.style.setProperty('--safe-area-top', '0px');
      document.documentElement.style.setProperty('--safe-area-bottom', '0px');
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Reveal What's Inside Your Skincare with SkinBuddy" />
        <meta
          property="og:description"
          content="Unlock the secrets of your skincare. SkinBuddy reveals the ingredients and matches you with products perfect for your skin."
        />
      </Helmet>
      {/* {IS_MOBILE_PLATFORM && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            width: '100%',
            height: 'var(--safe-area-top)',
            background: 'var(--white)',
          }}
        ></div>
      )} */}

      <Suspense fallback={<LoadingDisplay />}>
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={TIMEOUT_FOR_CSS_TRANSITION}
            classNames="fade"
            mountOnEnter
            unmountOnExit
            onEnter={handleAnimationStart}
          >
            <div ref={nodeRef}>
              {IS_WEB_PLATFORM && (
                <>
                  <div className={s.wrapper}>
                    <div className={s.topSide}>{currentOutlet}</div>
                    {isLoggedIn && pagesPathNeedFooter.includes(location.pathname) && (
                      <Footer
                        footerWrapper={clsx(s.footerWrapper, {
                          [s.footerPaddingWithTabBar]: pageWithTabBar.includes(location.pathname),
                        })}
                      />
                    )}
                  </div>
                  {isLoggedIn &&
                    (pageWithTabBar.includes(location.pathname) ||
                      location.pathname.includes('/product/')) && <TabBar />}
                </>
              )}

              {IS_MOBILE_PLATFORM && (
                <>
                  {currentOutlet}

                  {isLoggedIn &&
                    (pageWithTabBar.includes(location.pathname) ||
                      location.pathname.includes('/product/')) && <TabBar />}
                </>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </>
  );
};

export default Layout;
