import { createSlice } from '@reduxjs/toolkit';
import { MAKEUP_CATEGORIES, SKINCARE_CATEGORIES } from 'data/search';

const combinedCategories = [...SKINCARE_CATEGORIES, ...MAKEUP_CATEGORIES];

const uniqueCategories = Object.values(
  combinedCategories.reduce((acc, current) => {
    if (!acc[current.name]) {
      acc[current.name] = { ...current, is_selected: false };
    }
    return acc;
  }, {}),
);

const initialState = {
  currentInput: {
    ingredients: '',
    categories: uniqueCategories,
  },
  results: {
    ingredientsToAnalyse: '',
    concerns: [],
    summary: null,
  },
};

const ingredientAnalysisSlice = createSlice({
  name: 'ingredientAnalysis',
  initialState,
  reducers: {
    setIngredients: (state, { payload }) => {
      state.currentInput.ingredients = payload;
    },
    setSelectedCategory: (state, { payload }) => {
      state.currentInput.categories = state.currentInput.categories.map(el => ({
        ...el,
        is_selected: el.name === payload ? !el.is_selected : false,
      }));
    },
    setAnalysisResults: (state, { payload }) => {
      state.results.ingredientsToAnalyse = state.currentInput.ingredients;
      state.results.concerns = payload.concerns;
      state.results.summary = payload.summary;

      state.currentInput.ingredients = '';
      state.currentInput.categories = state.currentInput.categories.map(el => ({
        ...el,
        is_selected: false,
      }));
    },
  },
  extraReducers: builder => {},
});

export const { setAnalysisResults, setIngredients, setSelectedCategory } =
  ingredientAnalysisSlice.actions;

export default ingredientAnalysisSlice.reducer;
