import { VARIANT_LABELS_TYPE_LIST_CHECKBOX } from 'services/constants';

export const BEAUTY_PRODUCTS = [
  { name: 'Skincare', is_selected: true, img_name: 'skincare_icon.png' },
  { name: 'Makeup', is_selected: false, img_name: 'makeup_icon.png' },
];

export const SKINCARE_CATEGORIES = [
  {
    name: 'Cleanser',
    img_name: 'cleanser.png',
    is_selected: false,
  },
  {
    name: 'Moisturizer',
    img_name: 'moisturizer.png',
    is_selected: true,
  },
  {
    name: 'Sun Care',
    img_name: 'sun_care.png',
    is_selected: false,
  },
  { name: 'Serum', img_name: 'serum.png', is_selected: false },
  {
    name: 'Exfoliation',
    img_name: 'exfoliation.png',
    is_selected: false,
  },
  { name: 'Toner', img_name: 'toner.png', is_selected: false },
  {
    name: 'Makeup remover',
    img_name: 'makeup_remover.png',
    is_selected: false,
  },
  {
    name: 'BB/CC cream',
    img_name: 'bb_cc_cream.png',
    is_selected: false,
  },
  {
    name: 'Eye Care',
    img_name: 'eye_care.png',
    is_selected: false,
  },
  { name: 'Mask', img_name: 'mask.png', is_selected: false },
  {
    name: 'Treatment',
    img_name: 'treatment.png',
    is_selected: false,
  },
  { name: 'Face Oil', img_name: 'face_oil.png', is_selected: false },
];

export const MAKEUP_CATEGORIES = [
  {
    name: 'Foundation',
    img_name: 'foundation.png',
    is_selected: true,
  },
  {
    name: 'Blush',
    img_name: 'blush.png',
    is_selected: false,
  },
  {
    name: 'Concealer',
    img_name: 'concealer.png',
    is_selected: false,
  },
  {
    name: 'Highlighter',
    img_name: 'highlighter.png',
    is_selected: false,
  },
  {
    name: 'Powder',
    img_name: 'powder.png',
    is_selected: false,
  },
  {
    name: 'Contour',
    img_name: 'contour.png',
    is_selected: false,
  },
  {
    name: 'Primer',
    img_name: 'primer.png',
    is_selected: false,
  },
  {
    name: 'BB/CC cream',
    img_name: 'bb_cc_cream.png',
    is_selected: false,
  },
  {
    name: 'Bronzer',
    img_name: 'bronzer.png',
    is_selected: false,
  },
  {
    name: 'Setting spray',
    img_name: 'setting_spray.png',
    is_selected: false,
  },
];

const baseNotAppropriateSkinProblems = [
  'Puffiness',
  'Under-eye sensitivity',
  'Dark circles',
  'Dull under-eye skin',
  "Crow's feet",
  'Under-eye wrinkles',
  'Sagging eyelids',
  'Under-eye dryness',
];

export const NOT_APPROPRIATE_PROBLEMS_BY_CATEGORY = {
  Cleanser: baseNotAppropriateSkinProblems,
  Moisturizer: baseNotAppropriateSkinProblems,
  'Sun Care': baseNotAppropriateSkinProblems,
  Serum: baseNotAppropriateSkinProblems,
  Exfoliation: baseNotAppropriateSkinProblems,
  Toner: baseNotAppropriateSkinProblems,
  'Makeup remover': [
    'Puffiness',
    'Dark circles',
    'Dull under-eye skin',
    "Crow's feet",
    'Under-eye wrinkles',
    'Sagging eyelids',
  ],
  'BB/CC cream': baseNotAppropriateSkinProblems,
  'Eye Care': [
    'Fine lines',
    'Wrinkles',
    'Thinning skin',
    'Loss of firmness',
    'Age spots',
    'Decreased elasticity',
    'Acne-prone skin',
    'Blackheads',
    'Whiteheads',
    'Mild acne',
    'Severe acne',
    'Acne scars',
    'Post-acne marks',
    'Enlarged pores',
    'Sensitivity',
    'Dullness',
    'Hyperpigmentation',
    'Uneven texture',
    'Dehydrated skin',
    'Eczema',
    'Rosacea',
  ],
  Mask: baseNotAppropriateSkinProblems,
  Treatment: baseNotAppropriateSkinProblems,
  'Face Oil': baseNotAppropriateSkinProblems,
};

export const ADDITIONAL = {
  Cleanser: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Moisturizer: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Sun Care': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Serum: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Exfoliation: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Toner: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Makeup remover': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Eye Care': [
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Mask: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Treatment: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Face Oil': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],

  ////
  Foundation: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Blush: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Concealer: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Highlighter: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Powder: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Contour: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Primer: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  Bronzer: [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
  'Setting spray': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],

  //
  'BB/CC cream': [
    { name: 'Non-comedogenic', is_selected: false },
    { name: 'Non-irritating', is_selected: false },
    { name: 'Hypoallergenic', is_selected: false },
  ],
};

export const CATEGORIES_MAP = new Map([
  ['Cleanser', 'Cleanser'],
  ['Moisturizer', 'Moisturiser'],
  ['Sun Care', 'Sun Care'],
  ['Serum', 'Serum'],
  ['Exfoliation', 'Exfoliation'],
  ['Toner', 'Toner'],
  ['Makeup remover', 'Makeup remover'],
  ['BB/CC cream', 'BB/CC cream'],
  ['Eye Care', 'Eye Care'],
  ['Mask', 'Mask'],
  ['Treatment', 'Treatment'],
  ['Face Oil', 'Face oil'],
]);

export const PRICE_RANGE = [
  { range: 1, is_selected: false },
  { range: 2, is_selected: false },
  { range: 3, is_selected: false },
  { range: 4, is_selected: false },
];

export const LABELS_BY_CATEGORY = {
  Cleanser: [
    {
      title: 'What type of cleanser are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Gel', is_selected: false },
        { name: 'Cream', is_selected: false },
        { name: 'Foam', is_selected: false },
        { name: 'Lotion', is_selected: false },
        { name: 'Oil-based', is_selected: false },
        { name: 'Enzyme Powder', is_selected: false },
        { name: 'Balm', is_selected: false },
        { name: 'Soap', is_selected: false },
      ],
    },
    {
      title: 'What benefits are you looking for in a cleanser?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Brightening', is_selected: false },
        { name: 'Exfoliating', is_selected: false },
        { name: 'Hydrating', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
        { name: 'Acne Control / Reduction', is_selected: false },
        { name: 'Nourishing', is_selected: false },
        { name: 'Pore Minimizing', is_selected: false },
        { name: 'Purifying and Detoxifying', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Moisturizer: [
    {
      title: 'What type of moisturiser are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Cream', is_selected: false },
        { name: 'Emulsion', is_selected: false },
        { name: 'Gel', is_selected: false },
        { name: 'Balm', is_selected: false },
        { name: 'Lotion', is_selected: false },
      ],
    },
    {
      title: 'What benefits are you looking for in a moisturiser?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Brightening', is_selected: false },
        { name: 'Nourishing', is_selected: false },
        { name: 'Hydrating', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Firming and Lifting', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
        { name: 'Acne Control / Reduction', is_selected: false },
        { name: 'Texture Improvement', is_selected: false },
      ],
    },
    {
      title: 'Do you need SPF protection or a night-use moisturizer?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'SPF Included', is_selected: false },
        { name: 'Night only', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  'Sun Care': [
    {
      title: 'What type of sun care product are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Cream', is_selected: false },
        { name: 'Lotion', is_selected: false },
        { name: 'Stick', is_selected: false },
        { name: 'Spray', is_selected: false },
        { name: 'After Sun', is_selected: false },
      ],
    },
    {
      title: 'Which type of filters do you prefer in your sun care product?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Physical', is_selected: false },
        { name: 'Chemical', is_selected: false },
      ],
    },
    {
      title: 'What SPF level do you prefer?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'SPF45 or less', is_selected: false },
        { name: 'SPF50', is_selected: false },
        { name: 'SPF50+', is_selected: false },
      ],
    },
    {
      title: 'Looking for tinted coverage?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [{ name: 'Tinted', is_selected: false }],
    },
    {
      title: 'What benefits are you looking for in a sun care product?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Hydrating', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Serum: [
    {
      title: 'What benefits are you looking for in a serum?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Brightening', is_selected: false },
        { name: 'Exfoliating', is_selected: false },
        { name: 'Nourishing', is_selected: false },
        { name: 'Hydrating', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Firming and Lifting', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
        { name: 'Acne Control / Reduction', is_selected: false },
        { name: 'Pore Minimizing', is_selected: false },
        { name: 'Purifying and Detoxifying', is_selected: false },
        { name: 'Texture Improvement', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Exfoliation: [
    {
      title: 'What type of exfoliating product are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Pads', is_selected: false },
        { name: 'Enzyme Powder', is_selected: false },
        { name: 'Peeling', is_selected: false },
        { name: 'Scrub', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Toner: [
    {
      title: 'What type of toner are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Watery / Essence', is_selected: false },
        { name: 'Toner Pads', is_selected: false },
        { name: 'Mist / Spray', is_selected: false },
      ],
    },
    {
      title: 'What benefits are you looking for in a toner?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Brightening', is_selected: false },
        { name: 'Exfoliating', is_selected: false },
        { name: 'Nourishing', is_selected: false },
        { name: 'Hydrating', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
        { name: 'Pore Minimizing', is_selected: false },
        { name: 'Purifying and Detoxifying', is_selected: false },
        { name: 'Firming and Lifting', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  'Makeup remover': [
    {
      title: 'What type of makeup remover are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Bi-phase', is_selected: false },
        { name: 'Oil-based', is_selected: false },
        { name: 'Micellar Water', is_selected: false },
        { name: 'Balm', is_selected: false },
        { name: 'Milk', is_selected: false },
        { name: 'Micellar Wipes', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  'Eye Care': [
    {
      title: 'What type of eye care product are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Cream', is_selected: false },
        { name: 'Balm', is_selected: false },
        { name: 'Stick', is_selected: false },
        { name: 'Serum', is_selected: false },
        { name: 'Mask', is_selected: false },
        { name: 'Patches', is_selected: false },
      ],
    },
    {
      title: 'What benefits are you looking for in a eye care product?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Brightening', is_selected: false },
        { name: 'Nourishing', is_selected: false },
        { name: 'Hydrating', is_selected: false },
        { name: 'Firming and Lifting', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Mask: [
    {
      title: 'What type of face mask are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Sleeping', is_selected: false },
        { name: 'Sheet', is_selected: false },
        { name: 'Peel off', is_selected: false },
        { name: 'Wash off', is_selected: false },
      ],
    },
    {
      title: 'What benefits are you looking for in a face mask?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Brightening', is_selected: false },
        { name: 'Exfoliating', is_selected: false },
        { name: 'Nourishing', is_selected: false },
        { name: 'Hydrating', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Firming and Lifting', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
        { name: 'Acne Control / Reduction', is_selected: false },
        { name: 'Pore Minimizing', is_selected: false },
        { name: 'Purifying and Detoxifying', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Treatment: [
    {
      title: 'What type of treatment product are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Ampoule', is_selected: false },
        { name: 'Spot Treatment', is_selected: false },
        { name: 'Patch', is_selected: false },
        { name: 'Other', is_selected: false },
      ],
    },
    {
      title: 'What benefits are you looking for in a treatment product?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Brightening', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Firming and Lifting', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
        { name: 'Acne Control / Reduction', is_selected: false },
        { name: 'Pore Minimizing', is_selected: false },
        { name: 'Purifying and Detoxifying', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  'Face Oil': [
    {
      title: 'What benefits are you looking for in a face oil?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Nourishing', is_selected: false },
        { name: 'Hydrating', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Firming and Lifting', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
        { name: 'Acne Control / Reduction', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  'BB/CC cream': [
    {
      title: 'Are you looking for additional benefits in a BB/CC cream?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Hydrating', is_selected: false },
        { name: 'Nourishing', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
      ],
    },
    {
      title: 'Want SPF protection included?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [{ name: 'SPF Included', is_selected: false }],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],

  ////
  Foundation: [
    {
      title: 'What type of foundation are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Cushion', is_selected: false },
        { name: 'Compact', is_selected: false },
        { name: 'Other', is_selected: false },
      ],
    },
    {
      title: 'Are you looking for additional benefits in a foundation?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Hydrating', is_selected: false },
        { name: 'Nourishing', is_selected: false },
        { name: 'Soothing and Calming', is_selected: false },
        { name: 'Sebum Regulation', is_selected: false },
      ],
    },
    {
      title: 'Want SPF protection included?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [{ name: 'SPF Included', is_selected: false }],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Blush: [
    {
      title: 'What type of blush are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Stick', is_selected: false },
        { name: 'Cream', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Concealer: [
    {
      title: 'What type of concealer are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Stick', is_selected: false },
        { name: 'Cream', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Highlighter: [
    {
      title: 'What type of highlighter are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Powder', is_selected: false },
        { name: 'Cream', is_selected: false },
        { name: 'Stick', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Powder: [
    {
      title: 'What type of powder are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Loose', is_selected: false },
        { name: 'Compact', is_selected: false },
      ],
    },
    {
      title: 'Want SPF protection included?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [{ name: 'SPF Included', is_selected: false }],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Contour: [
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Primer: [
    {
      title: 'Want SPF protection included?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [{ name: 'SPF Included', is_selected: false }],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  Bronzer: [
    {
      title: 'What type of bronzer are you looking for?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Cream', is_selected: false },
        { name: 'Powder', is_selected: false },
        { name: 'Stick', is_selected: false },
      ],
    },
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
  'Setting spray': [
    {
      title: 'What’s essential for your skin?',
      type: VARIANT_LABELS_TYPE_LIST_CHECKBOX,
      list: [
        { name: 'Non-comedogenic', is_selected: false },
        { name: 'Non-irritating', is_selected: false },
        { name: 'Hypoallergenic', is_selected: false },
      ],
    },
  ],
};
