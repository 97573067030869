import { SKIN_CONDITIONS_MAP, SKIN_CONDITIONS_ONLY_FOR_UI } from 'data/profile';
import { EMAIL_PATTERN_AUTH, PASSWORD_PATTERN_AUTH } from './constants';

export const makeAQueryStringFromAnArray = arr => arr.join(',').replaceAll(' ', '_');
export const makeAQueryArrayFromAnString = str => str.replaceAll('_', ' ').split(',');

export const getSkinType = (skinTypeList = []) => {
  const defaultSkinType = 'Normal';
  const selectedSkinType =
    skinTypeList.find(({ is_selected }) => is_selected)?.name || defaultSkinType;

  return SKIN_CONDITIONS_MAP.get(selectedSkinType);
};

const flattenConcerns = concerns => {
  if (typeof concerns === 'object' && concerns !== null) return Object.values(concerns).flat();

  return concerns;
};

export const createSrcSetByStaticImageWithRatio = ({ folder, img_name, ratios = [2] }) => {
  const basePath = `/images/${folder}`;

  return ratios
    .map(ratio => `${basePath}/${img_name.replace('.', `@${ratio}x.`)} ${ratio}x`)
    .join(', ');
};

export const createProductLinkWithIdAndConcerns = (id, concerns = [], category) => {
  const queryParams = new URLSearchParams();
  queryParams.append('concerns', makeAQueryStringFromAnArray(concerns));

  if (category) {
    queryParams.append('category', category);
  }

  return `/product/${id}?${queryParams.toString()}`;
};

export const makeRandomNumberFromMinToMax = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);

export const getRandomElement = array => {
  const randomIndex = makeRandomNumberFromMinToMax(0, array.length);
  return array[randomIndex];
};

export const createSrcByStaticImage = ({ folder, img_name }) => `/images/${folder}/${img_name}`;

export const checkAndSetAlternativeIds = (productList, productExistenceChecker, productId) => {
  const productExists = productList.some(productExistenceChecker);
  if (productExists) {
    return productList.filter(({ id }) => id !== productId).map(({ id }) => id);
  }
  return [];
};

export const matchClassNameByValue = match => {
  if (match === 0) return 'neutral';
  if (match >= 90) return 'good';
  if (match >= 80) return 'medium';
  return 'bad';
};

export const getConcernsSetting = (skinTypeList, concerns = []) => {
  const skinType = getSkinType(skinTypeList);

  const allConcerns = concerns
    .flatMap(flattenConcerns)
    .filter(
      el =>
        el.is_selected &&
        el.name !== 'I don’t have acne concerns' &&
        el.name !== 'I don’t have them',
    )
    .map(el => SKIN_CONDITIONS_MAP.get(el.name) || el.name);

  return [skinType, ...allConcerns];
};

export const getConcernsSettingWithoutFlattening = (skinTypeList, concerns = []) => {
  const skinType = getSkinType(skinTypeList);

  const allConcerns = concerns.map(el => SKIN_CONDITIONS_MAP.get(el.name) || el.name);

  return [skinType, ...allConcerns];
};

export const getSbCategory = (subCategories = []) =>
  subCategories.find(el => el.is_selected)?.name || 'Cleanser';

export const getSearchParameters = (subCategories, skinType, ...allConcerns) => {
  const subCategory = getSbCategory(subCategories);

  const concerns = getConcernsSetting(skinType, allConcerns);

  return {
    subCategory,
    concerns,
  };
};

export const getActiveConcernsSettings = (skinTypeList = [], concernsList = []) => {
  const skinType = getSkinType(skinTypeList);

  const concerns = concernsList
    .flatMap(flattenConcerns)
    .filter(
      el =>
        el.is_selected &&
        el.name !== 'I don’t have acne concerns' &&
        el.name !== 'I don’t have them',
    )
    .map(({ name }) => SKIN_CONDITIONS_ONLY_FOR_UI[name] || name);

  return [skinType, ...concerns];
};

export const getSelectedConcerns = (...concerns) =>
  concerns
    .flatMap(flattenConcerns)
    .filter(
      el =>
        el.is_selected &&
        el.name !== 'I don’t have acne concerns' &&
        el.name !== 'I don’t have them',
    )
    .map(el => ({ ...el, name: SKIN_CONDITIONS_ONLY_FOR_UI[el.name] || el.name }));

export const getPriceFilterForRequest = (priceRange = []) => {
  const price_filter = priceRange
    .filter(el => el.is_selected)
    .map(el => el.range)
    .join(',');
  return price_filter ? [price_filter] : [];
};

export const getLabelsForResults = list => {
  const selectedLabels = list.filter(el => el.is_selected);
  const labelNames = selectedLabels.map(el => el.name);

  return { selectedLabels, labelNames };
};

export const getLabelsForSmartFilter = (data = []) => {
  const { selectedLabels, labelNames } = data.reduce(
    (acc, section) => {
      section.list.forEach(item => {
        if (item.is_selected) {
          acc.selectedLabels.push(item);
          acc.labelNames.push(SKIN_CONDITIONS_MAP.get(item.name) || item.name);
        }
      });
      return acc;
    },
    { selectedLabels: [], labelNames: [] },
  );

  return { selectedLabels, labelNames };
};

export const updateItem = (currentItems, name, setState) => {
  const updatedItems = currentItems.map(el => {
    if (el.name === name) {
      return { ...el, is_selected: !el.is_selected };
    } else {
      return el;
    }
  });

  setState(updatedItems);
};

export const isPathMatch = (routePath, currentPath) => {
  if (!routePath.includes('/:')) return routePath === currentPath;

  const basePath = routePath.split('/:')[0];
  return currentPath.startsWith(basePath);
};

export const encode_utf8 = s => {
  return unescape(encodeURIComponent(s));
};

export const substr_utf8_bytes = (str, startInBytes, lengthInBytes) => {
  /* this function scans a multibyte string and returns a substring. 
    * arguments are start position and length, both defined in bytes.
    * 
    * this is tricky, because javascript only allows character level 
    * and not byte level access on strings. Also, all strings are stored
    * in utf-16 internally - so we need to convert characters to utf-8
    * to detect their length in utf-8 encoding.
    *
    * the startInBytes and lengthInBytes parameters are based on byte 
    * positions in a utf-8 encoded string.
    * in utf-8, for example: 
    *       "a" is 1 byte, 
            "ü" is 2 byte, 
       and  "你" is 3 byte.
    *
    * NOTE:
    * according to ECMAScript 262 all strings are stored as a sequence
    * of 16-bit characters. so we need a encode_utf8() function to safely
    * detect the length our character would have in a utf8 representation.
    * 
    * http://www.ecma-international.org/publications/files/ecma-st/ECMA-262.pdf
    * see "4.3.16 String Value":
    * > Although each value usually represents a single 16-bit unit of 
    * > UTF-16 text, the language does not place any restrictions or 
    * > requirements on the values except that they be 16-bit unsigned 
    * > integers.
    */

  let resultStr = '';
  let bytePos = 0;
  let startInChars = 0;

  // scan string forward to find index of first character
  // (convert start position in byte to start position in characters)

  for (bytePos = 0; bytePos < startInBytes; startInChars++) {
    // get numeric code of character (is >= 128 for multibyte character)
    // and increase "bytePos" for each byte of the character sequence

    const ch = str.charCodeAt(startInChars);
    bytePos += ch < 128 ? 1 : encode_utf8(str[startInChars]).length;
  }

  // now that we have the position of the starting character,
  // we can built the resulting substring

  // as we don't know the end position in chars yet, we start with a mix of
  // chars and bytes. we decrease "end" by the byte count of each selected
  // character to end up in the right position
  let end = startInChars + lengthInBytes - 1;

  for (let n = startInChars; startInChars <= end; n++) {
    // get numeric code of character (is >= 128 for multibyte character)
    // and decrease "end" for each byte of the character sequence
    const ch = str.charCodeAt(n);
    end -= ch < 128 ? 1 : encode_utf8(str[n]).length;

    resultStr += str[n];
  }

  return resultStr;
};

export const createAndFocusFakeInput = () => {
  const fakeInput = document.createElement('input');
  fakeInput.setAttribute('type', 'text');
  fakeInput.setAttribute('id', 'fakeInputId');
  fakeInput.style.position = 'absolute';
  fakeInput.style.opacity = '0';
  fakeInput.style.height = '0';
  fakeInput.style.fontSize = '16px';
  fakeInput.readOnly = true;
  document.body.prepend(fakeInput);
  fakeInput.focus();
};

export const focusElementByRef = ref => {
  const fakeInput = document.querySelector('#fakeInputId');
  setTimeout(() => {
    ref.current?.focus();
    if (fakeInput) {
      fakeInput.remove();
    }
  }, 0);
};

export const resizeBase64Image = base64Image => {
  return new Promise((resolve, reject) => {
    const maxSizeInMB = 1;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const img = new Image();
    img.src = base64Image;
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const width = img.width;
      const height = img.height;
      const aspectRatio = width / height;
      const newWidth = Math.sqrt(maxSizeInBytes * aspectRatio);
      const newHeight = Math.sqrt(maxSizeInBytes / aspectRatio);
      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      let quality = 0.9;
      let dataURL = canvas.toDataURL('image/jpeg', quality);
      resolve(dataURL);
    };
  });
};

export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const optimizeImageForUpload = async imageBase64 => {
  const sizeInBytes = (imageBase64.length * 3) / 4 - (imageBase64.match(/=/g) || []).length;
  if (sizeInBytes > 1048576) {
    return await resizeBase64Image(imageBase64);
  } else {
    return imageBase64;
  }
};

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const validatePassword = password => PASSWORD_PATTERN_AUTH.test(password);
export const validateEmail = email => EMAIL_PATTERN_AUTH.test(email);
