import { createSlice } from '@reduxjs/toolkit';
import {
  BEAUTY_PRODUCTS,
  LABELS_BY_CATEGORY,
  MAKEUP_CATEGORIES,
  PRICE_RANGE,
  SKINCARE_CATEGORIES,
} from 'data/search';

const initialState = {
  settings: {
    mainCategories: BEAUTY_PRODUCTS,
    subCategories: SKINCARE_CATEGORIES, // Default to Skincare categories
    priceRange: PRICE_RANGE,
    labels: LABELS_BY_CATEGORY,
    selectedSubCategories: {
      Skincare: 'Moisturizer',
      Makeup: 'Foundation',
    },
  },
  results: [],
  activeFilters: {
    concerns: [],
    labels: [],
    mainCategory: 'Skincare',
    subCategory: 'Moisturizer',
    skinType: '',
    priceRange: PRICE_RANGE,
  },
};

const smartFilterSlice = createSlice({
  name: 'smartFilter',
  initialState,
  reducers: {
    selectMainCategory: (state, { payload }) => {
      state.settings.mainCategories = state.settings.mainCategories.map(el => ({
        ...el,
        is_selected: el.name === payload,
      }));

      const selectedSubCategory = state.settings.selectedSubCategories[payload];
      const newSubCategories = (
        payload === 'Skincare' ? SKINCARE_CATEGORIES : MAKEUP_CATEGORIES
      ).map(sub => ({
        ...sub,
        is_selected: sub.name === selectedSubCategory,
      }));

      state.settings.subCategories = newSubCategories;
    },
    selectSubCategory: (state, { payload }) => {
      const mainCategory = state.settings.mainCategories.find(
        category => category.is_selected,
      ).name;

      state.settings.subCategories = state.settings.subCategories.map(sub => ({
        ...sub,
        is_selected: sub.name === payload,
      }));

      state.settings.selectedSubCategories[mainCategory] = payload;
    },
    togglePriceRangeSettings: (state, { payload }) => {
      state.settings.priceRange = state.settings.priceRange.map(el => {
        if (el.range === payload) {
          return { ...el, is_selected: !el.is_selected };
        } else {
          return el;
        }
      });
    },
    togglePriceRangeFilter: (state, { payload }) => {
      state.activeFilters.priceRange = state.activeFilters.priceRange.map(el => {
        if (el.range === payload) {
          return { ...el, is_selected: !el.is_selected };
        } else {
          return el;
        }
      });
    },
    toggleAdditionalSetting: (state, { payload: { subCategory, additionalName } }) => {
      state.settings.additional[subCategory] = state.settings.additional[subCategory].map(el => {
        if (el.name === additionalName) {
          return { ...el, is_selected: !el.is_selected };
        } else {
          return el;
        }
      });
    },
    toggleLabelSetting: (state, { payload: { subCategory, labelName, sectionIndex } }) => {
      const category = state.settings.labels[subCategory][sectionIndex];
      const categoryType = category.type;

      if (categoryType === 'radio') {
        category.list = category.list.map(el => ({
          ...el,
          is_selected: el.name === labelName ? !el.is_selected : false,
        }));
      } else if (categoryType === 'checkbox') {
        category.list = category.list.map(el => {
          if (el.name === labelName) {
            return { ...el, is_selected: !el.is_selected };
          } else {
            return el;
          }
        });
      }
    },
    setSmartFilterResults: (state, { payload }) => {
      state.results = payload;
    },

    setActiveFilter: (state, { payload }) => {
      state.activeFilters.mainCategory = payload.mainCategory;
      state.activeFilters.subCategory = payload.subCategory;
      state.activeFilters.skinType = payload.skinType;
      state.activeFilters.concerns = payload.selectedConcerns;
      state.activeFilters.labels = payload.selectedLabels;
      state.activeFilters.priceRange = payload.priceRange;
    },

    setActiveFilterConcerns: (state, { payload }) => {
      state.activeFilters.concerns = payload;
    },
    updateActiveFilterConcerns: (state, { payload }) => {
      state.activeFilters.concerns = state.activeFilters.concerns.map(el => {
        if (el.name === payload) {
          return { ...el, is_selected: !el.is_selected };
        } else {
          return el;
        }
      });
    },
    updateActiveFilterLabels: (state, { payload }) => {
      state.activeFilters.labels = state.activeFilters.labels.map(el => {
        if (el.name === payload) {
          return { ...el, is_selected: !el.is_selected };
        } else {
          return el;
        }
      });
    },
    setSkinTypeInResults: (state, { payload }) => {
      state.activeFilters.skinType = payload;
    },
  },
  extraReducers: builder => {},
});
export const {
  selectMainCategory,
  selectSubCategory,
  togglePriceRangeSettings,
  toggleAdditionalSetting,
  setSmartFilterResults,
  setActiveFilterConcerns,
  updateActiveFilterConcerns,
  updateActiveFilterLabels,
  setSkinTypeInResults,
  togglePriceRangeFilter,
  toggleLabelSetting,
  setActiveFilter,
} = smartFilterSlice.actions;

export default smartFilterSlice.reducer;
