import { createSlice } from '@reduxjs/toolkit';

const initialState = { recentSearchQueries: [], filter: '' };

const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    setFilter: (state, { payload }) => {
      state.filter = payload;
    },
    addRecentSearchQuery: (state, { payload }) => {
      const filteredPrev = state.recentSearchQueries.filter(query => query !== payload);
      state.recentSearchQueries = [payload, ...filteredPrev.slice(0, 14)];
    },
    removeRecentSearchQuery: (state, { payload }) => {
      state.recentSearchQueries = state.recentSearchQueries.filter(query => query !== payload);
    },
    resetRecentSearches: state => {
      state.recentSearchQueries = [];
    },
  },
  extraReducers: builder => {},
});

export const { setFilter, addRecentSearchQuery, removeRecentSearchQuery, resetRecentSearches } =
  productSearchSlice.actions;

export default productSearchSlice.reducer;
