import axios from 'axios';
import { store } from 'state-management/store';

import {
  API_ENDPOINT_ANALYZE,
  API_ENDPOINT_INGREDIENT,
  API_ENDPOINT_PRODUCT,
  API_ENDPOINT_RECOMMEND,
  API_ENDPOINT_SCAN_PRODUCT,
  API_ENDPOINT_SEARCH_QUERY,
  API_ENDPOINT_SHOW_CASE,
  API_ENDPOINT_GROW_DATABASE,
  API_AUTH_ENDPOINT_SIGN_UP,
  API_AUTH_ENDPOINT_SIGN_IN,
  API_AUTH_ENDPOINT_VERIFY_ACCOUNT,
  API_AUTH_ENDPOINT_FORGOT_PASSWORD,
  API_AUTH_ENDPOINT_FORGOT_PASSWORD_VERIFY,
} from './constants';
import { API_BASE_URL } from './environment';
import { authActions } from 'state-management/auth';

export const instance = axios.create({
  baseURL: API_BASE_URL,
});

export const instanceAuth = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.request.use(
  config => {
    const token = store.getState().auth.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      store.dispatch(authActions.logout());
    }

    return Promise.reject(error);
  },
);

export const getRecommendProducts = async ({ subCategory, concerns }, pr = [], labels = []) => {
  const { data } = await instance.post(API_ENDPOINT_RECOMMEND, {
    [subCategory]: concerns,
    pr,
    labels,
  });
  return data;
};

export const getProductByIdAndConcerns = async (id, concerns, alternativeIds = []) => {
  const { data } = await instance.post(API_ENDPOINT_PRODUCT, {
    [id]: {
      concerns,
      ids: alternativeIds,
    },
  });
  return data;
};

export const getIngredientInfo = async (ingredientName, concern) => {
  const { data } = await instance.post(API_ENDPOINT_INGREDIENT, {
    [ingredientName]: concern,
  });
  return data.description;
};

export const getSearchProducts = async (filterString, concerns = []) => {
  const { data } = await instance.post(API_ENDPOINT_SEARCH_QUERY, {
    [filterString]: concerns,
  });
  return data;
};

export const getPersonalizedProducts = async concerns => {
  const { data } = await instance.post(API_ENDPOINT_SHOW_CASE, { concerns });
  return data;
};

export const getAnalyzeIngredient = async (
  ingredients,
  concerns = [],
  subCategory = '',
  config = {},
) => {
  let body = { ingredients: [ingredients], concerns };

  if (subCategory) {
    body.category = [subCategory];
  }
  const { data } = await instance.post(API_ENDPOINT_ANALYZE, body, config);
  return data;
};

export const getProductInfoByImage = async (fileBase64 = '', concerns, config = {}) => {
  const { data } = await instance.post(
    API_ENDPOINT_SCAN_PRODUCT,
    { img: [fileBase64], concerns },
    config,
  );
  return data;
};

// GET /api/v1/grow?b=test_brand2&p=test_product2&i=test_insights2, where i is optional
export const growDatabaseWithProduct = async ({ brand, product, insights }) => {
  const encodedBrand = encodeURIComponent(brand);
  const encodedProduct = encodeURIComponent(product);
  const encodedInsights = insights ? encodeURIComponent(insights) : '';

  let url = `${API_ENDPOINT_GROW_DATABASE}?b=${encodedBrand}&p=${encodedProduct}`;

  if (encodedInsights) {
    url += `&i=${encodedInsights}`;
  }

  await instance.get(url);
};

// auth

export const signUpAPI = async credentials => {
  const { data } = await instanceAuth.post(API_AUTH_ENDPOINT_SIGN_UP, credentials);
  return data;
};

export const signInAPI = async credentials => {
  const { data } = await instanceAuth.post(API_AUTH_ENDPOINT_SIGN_IN, credentials);
  return data;
};

export const verifyAccountAPI = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await instanceAuth.get(API_AUTH_ENDPOINT_VERIFY_ACCOUNT, config);
  return data;
};

export const forgotPasswordAPI = async credentials => {
  const { data } = await instanceAuth.post(API_AUTH_ENDPOINT_FORGOT_PASSWORD, credentials);
  return data;
};

export const forgotPasswordVerifyAPI = async credentials => {
  const { data } = await instanceAuth.post(API_AUTH_ENDPOINT_FORGOT_PASSWORD_VERIFY, credentials);
  return data;
};

export const getUserAPI = async () => {};
