import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
  persistReducer,
} from 'redux-persist';
import authReducer from './auth/authSlice';
import skinProfileReducer from './skinProfile/skinProfileSlice';
import smartFilterReducer from './smartFilter/smartFilterSlice';
import favoritesReducer from './favorites/favoritesSlice';
import productSearchReducer from './productSearch/productSearchSlice';
import appSettingsReducer from './appSettings/appSettingsSlice';
import ingredientAnalysisReducer from './ingredientAnalysis/ingredientAnalysisSlice';

import {
  appSettingsPersistConfig,
  authPersistConfig,
  favoritesPersistConfig,
  ingredientAnalysisPersistConfig,
  productSearchPersistConfig,
  skinProfilePersistConfig,
  smartFilterPersistConfig,
} from './persistConfig';

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    skinProfile: persistReducer(skinProfilePersistConfig, skinProfileReducer),
    smartFilter: persistReducer(smartFilterPersistConfig, smartFilterReducer),
    favorites: persistReducer(favoritesPersistConfig, favoritesReducer),
    productSearch: persistReducer(productSearchPersistConfig, productSearchReducer),
    appSettings: persistReducer(appSettingsPersistConfig, appSettingsReducer),
    ingredientAnalysis: persistReducer(ingredientAnalysisPersistConfig, ingredientAnalysisReducer),
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

/*
const state = {
  auth: { isSubscribed: false },

  skinProfile: {
    skinType: SKIN_TYPE,
    skinConcerns: {
      checkbox: SKIN_CONCERNS_CHECKBOX,
    },
    acneIssues: {
      radio: ACNE_ISSUES_RADIO,
      checkbox: ACNE_ISSUES_CHECKBOX,
    },
    agingSigns: {
      radio: AGING_SIGNS_RADIO,
      checkbox: AGING_SIGNS_CHECKBOX,
    },
    eyeCare: {
      radio: EYE_CONCERNS_RADIO,
      checkbox: EYE_CONCERNS_CHECKBOX,
    },
    avoidedIngredients: SPECIFIC_INGREDIENTS_FOR_AVOID,
  },

  smartFilter: {
    settings: {
      mainCategories: BEAUTY_PRODUCTS,
      subCategories: SKINCARE_CATEGORIES, // Default to Skincare categories
      priceRange: PRICE_RANGE,
      labels: LABELS_BY_CATEGORY,
      selectedSubCategories: {
        Skincare: 'Moisturizer',
        Makeup: 'Foundation',
      },
    },
    results: [],
    activeFilters: {
      concerns: [],
      labels: [],
      mainCategory: 'Skincare',
      subCategory: 'Moisturizer',
      skinType: '',
      priceRange: PRICE_RANGE,
    },
  },

  favorites: {
    list: [],
  },

  productSearch: {
    recentSearchQueries: [],
    filter: '',
  },

  appSettings: {
    isAcceptTerms: false,
    isShowHelperProductPopup: true,
    originListName: '',
    timesShowAddedToFavoriteNotification: 0,
    homePageMainText: getRandomText(),
  },

  ingredientAnalysis: {
    currentInput: {
      ingredients: '',
      categories: uniqueCategories,
    },
    results: {
      ingredientsToAnalyse: '',
      concerns: [],
      summary: null,
    },
  },
};
*/
