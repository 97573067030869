import { createSlice } from '@reduxjs/toolkit';
import {
  verifyAccount,
  signIn,
  forgotPasswordVerify,
  signUp,
  forgotPassword,
} from './authOperations';

const initialState = {
  isLoading: false,
  isLoadingUser: false,
  errorMessage: '',
  token: '',
  isSubscribed: false,
};

const handlerPending = state => {
  state.errorMessage = '';
  state.isLoading = true;
};

const handlerRejected = (state, { payload }) => {
  state.errorMessage = payload;
  state.isLoading = false;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    subscribe: (state, { payload }) => {
      state.isSubscribed = true;
    },
    restoreSubscribe: state => {
      state.isSubscribed = true;
    },
    unsubscribe: state => {
      state.isSubscribed = false;
    },
    clearErrorMessage: state => {
      state.errorMessage = '';
    },
    logout: state => {
      state.token = '';
      state.errorMessage = '';
      state.isSubscribed = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signUp.pending, handlerPending)
      .addCase(signUp.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(signUp.rejected, handlerRejected)

      .addCase(verifyAccount.pending, handlerPending)
      .addCase(verifyAccount.fulfilled, (state, { payload }) => {
        state.token = payload;
        state.isLoading = false;
      })
      .addCase(verifyAccount.rejected, handlerRejected)

      .addCase(signIn.pending, handlerPending)
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.token = payload;
        state.isLoading = false;
      })
      .addCase(signIn.rejected, handlerRejected)

      .addCase(forgotPassword.pending, handlerPending)
      .addCase(forgotPassword.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, handlerRejected)

      .addCase(forgotPasswordVerify.pending, handlerPending)
      .addCase(forgotPasswordVerify.fulfilled, (state, { payload }) => {
        state.token = payload;
        state.isLoading = false;
      })
      .addCase(forgotPasswordVerify.rejected, handlerRejected);
  },
});

export const { restoreSubscribe, subscribe, unsubscribe, clearErrorMessage, logout } =
  authSlice.actions;

export default authSlice.reducer;
