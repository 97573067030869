import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { appSettingsSelectors } from 'state-management/appSettings';

import { authSelectors } from 'state-management/auth';

const PublicRoute = ({ children, redirectTo = '/' }) => {
  const location = useLocation();
  const isLoggedIn = useSelector(authSelectors.selectAuthIsLoggedIn);

  const isFirstVisitMobileApp = useSelector(appSettingsSelectors.selectIsFirstVisitMobileApp);

  if (isFirstVisitMobileApp && location.pathname !== '/welcome') {
    return <Navigate to="/welcome" replace />;
  }

  return isLoggedIn ? <Navigate to={redirectTo} replace /> : children;
};

export default PublicRoute;
