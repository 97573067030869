import storage from 'redux-persist/lib/storage';

import {
  SKIN_CONCERNS_CHECKBOX,
  SKIN_TYPE,
  ACNE_ISSUES_CHECKBOX,
  ACNE_ISSUES_RADIO,
  AGING_SIGNS_CHECKBOX,
  AGING_SIGNS_RADIO,
  EYE_CONCERNS_CHECKBOX,
  EYE_CONCERNS_RADIO,
} from 'data/profile';
import { LS_USER_DATA_KEY } from 'services/constants';
import * as localStorageService from 'services/localStorage';

export const splitConcerns = (oldConcerns, radioList, checkboxList) => {
  if (!oldConcerns.length) {
    return {
      radio: radioList,
      checkbox: checkboxList,
    };
  }

  const selectedOldNames = new Set(oldConcerns.filter(old => old.is_selected).map(old => old.name));

  let lastSelectedIndex = -1;

  const radioConcerns = radioList.map((radio, index) => {
    const isSelected =
      selectedOldNames.has(radio.name) ||
      [...selectedOldNames].some(name => radio.name.toLowerCase().includes(name.toLowerCase()));

    if (isSelected) {
      lastSelectedIndex = index; // Track the last selected index
    }

    return {
      ...radio,
      is_selected: false, // Initially set all to false
    };
  });

  // Set the last selected radio concern to true, or the default "don’t have concerns" if none were selected
  if (lastSelectedIndex !== -1) {
    radioConcerns[lastSelectedIndex].is_selected = true;
  } else {
    radioConcerns[radioConcerns.length - 1].is_selected = true;
  }

  const checkboxConcerns = checkboxList.map(checkbox => ({
    ...checkbox,
    is_selected: selectedOldNames.has(checkbox.name),
  }));

  return {
    radio: radioConcerns,
    checkbox: checkboxConcerns,
  };
};

export const createMigrateToVersion1 = () => {
  const oldData = localStorageService.get(LS_USER_DATA_KEY);
  let migratedStateCache = null;

  if (oldData) {
    migratedStateCache = {
      favorites: { list: oldData.favorites || [] },
      skinProfile: {
        skinType: oldData.main?.settings?.skinType || SKIN_TYPE,
        skinConcerns: {
          checkbox: oldData.main?.settings?.skinConcerns || SKIN_CONCERNS_CHECKBOX,
        },
        acneIssues: splitConcerns(
          oldData.main?.settings?.acneIssues || [],
          ACNE_ISSUES_RADIO,
          ACNE_ISSUES_CHECKBOX,
        ),
        agingSigns: splitConcerns(
          oldData.main?.settings?.agingSings || [],
          AGING_SIGNS_RADIO,
          AGING_SIGNS_CHECKBOX,
        ),
        eyeCare: splitConcerns(
          oldData.main?.settings?.eye || [],
          EYE_CONCERNS_RADIO,
          EYE_CONCERNS_CHECKBOX,
        ),
      },
      appSettings: {
        // Production V 1.0 don't have isAcceptTermsAndConditions
        isAcceptTerms: oldData.isAcceptTermsAndConditions ?? true,

        isShowHelperProductPopup: oldData.isShowHelperProductPopup ?? true,
        timesShowAddedToFavoriteNotification: oldData.timesShowAddedToFavoriteNotification ?? 0,
      },
      productSearch: {
        recentSearchQueries: oldData.recentSearches || [],
      },
    };

    localStorageService.remove(LS_USER_DATA_KEY);
  }

  return async (state = {}, key) => {
    if (migratedStateCache) {
      return {
        ...state,
        ...migratedStateCache[key],
      };
    }

    return state;
  };
};

// Create an instance of the migration function with closure-based cache
const migrateToVersion1 = createMigrateToVersion1();

export const ingredientAnalysisPersistConfig = {
  key: 'ingredientAnalysis',
  storage: storage,
  whitelist: ['results'],
  version: 1,
};

export const appSettingsPersistConfig = {
  key: 'appSettings',
  storage: storage,
  version: 1,
  migrate: state => migrateToVersion1(state, 'appSettings'),
};

export const skinProfilePersistConfig = {
  key: 'skinProfile',
  storage: storage,
  version: 1,
  migrate: state => migrateToVersion1(state, 'skinProfile'),
};

export const favoritesPersistConfig = {
  key: 'favorites',
  storage,
  version: 1,
  migrate: state => migrateToVersion1(state, 'favorites'),
};

export const productSearchPersistConfig = {
  key: 'productSearch',
  storage: storage,
  whitelist: ['recentSearchQueries'],
  version: 1,
  migrate: state => migrateToVersion1(state, 'productSearch'),
};

export const smartFilterPersistConfig = {
  key: 'smartFilter',
  storage,
  whitelist: ['results', 'activeFilters'],
  version: 1,
};

export const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token'],
  version: 1,
};
