import {
  forgotPasswordAPI,
  forgotPasswordVerifyAPI,
  signInAPI,
  signUpAPI,
  verifyAccountAPI,
} from 'services/api';

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ERROR_AUTH_API_EXISTING_USER,
  ERROR_AUTH_API_WRONG_EMAIL_OR_PASSWORD,
} from 'services/constants';

export const signUp = createAsyncThunk('auth/signUp', async (credentials, thunkAPI) => {
  try {
    const data = await signUpAPI(credentials);

    if (data?.error) {
      return thunkAPI.rejectWithValue(data.error);
    }

    return true;
  } catch (error) {
    const errorMessage = error?.response?.data?.error;

    if (errorMessage === ERROR_AUTH_API_EXISTING_USER) {
      return thunkAPI.rejectWithValue(
        'This email is already in use. Try logging in or use a different email.',
      );
    }

    if (error?.response?.data?.code === 422) {
      const errorData = error.response.data.errors;
      let combinedMessage = '';

      if (errorData?.email) {
        combinedMessage += 'Oops! That doesn’t look like a valid email address.';
      }

      if (errorData?.pwd_hash) {
        if (combinedMessage) combinedMessage += ' ';
        combinedMessage += errorData.pwd_hash[0]?.code || 'Password is invalid.';
      }

      combinedMessage += '  Please check and try again.';

      return thunkAPI.rejectWithValue(combinedMessage);
    }

    return thunkAPI.rejectWithValue(
      errorMessage || 'Something went wrong, please try again later.',
    );
  }
});

export const signIn = createAsyncThunk('auth/signIn', async (credentials, thunkAPI) => {
  try {
    const data = await signInAPI(credentials);

    if (data?.error) {
      return thunkAPI.rejectWithValue(data.error);
    }

    return data;
  } catch (error) {
    const errorMessage = error?.response?.data?.error;

    if (errorMessage === ERROR_AUTH_API_WRONG_EMAIL_OR_PASSWORD) {
      return thunkAPI.rejectWithValue(
        'We couldn’t find an account with that email and password. Please try again.',
      );
    }

    if (error?.response?.data?.code === 422) {
      const errorData = error.response.data.errors;
      let combinedMessage = '';

      if (errorData?.email) {
        combinedMessage += 'Oops! That doesn’t look like a valid email address.';
      }

      if (errorData?.pwd_hash) {
        if (combinedMessage) combinedMessage += ' ';
        combinedMessage += errorData.pwd_hash[0]?.code || 'Password is invalid.';
      }

      combinedMessage += '  Please check and try again.';

      return thunkAPI.rejectWithValue(combinedMessage);
    }

    return thunkAPI.rejectWithValue(
      errorMessage || 'Something went wrong, please try again later.',
    );
  }
});

export const verifyAccount = createAsyncThunk('auth/verifyAccount', async (token, thunkAPI) => {
  try {
    const data = await verifyAccountAPI(token);

    if (data?.error) {
      return thunkAPI.rejectWithValue(data.error);
    }

    return data;
  } catch (error) {
    console.log('🚀 ~ verifyAccount ~ error:', error);

    const errorMessage = error?.response?.data?.error;

    return thunkAPI.rejectWithValue(
      errorMessage || 'Something went wrong, please try again later.',
    );
  }
});

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (credentials, thunkAPI) => {
    try {
      const data = await forgotPasswordAPI(credentials);

      if (data?.error) {
        return thunkAPI.rejectWithValue(data.error);
      }

      return true;
    } catch (error) {
      const errorMessage = error?.response?.data?.error;

      return thunkAPI.rejectWithValue(
        errorMessage || 'Something went wrong, please try again later.',
      );
    }
  },
);

export const forgotPasswordVerify = createAsyncThunk(
  'auth/forgotPasswordVerify',
  async (credentials, thunkAPI) => {
    try {
      const data = await forgotPasswordVerifyAPI(credentials);

      if (data?.error) {
        return thunkAPI.rejectWithValue(data.error);
      }

      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.error;

      if (error?.response?.data?.code === 422) {
        const errorData = error.response.data.errors;
        let combinedMessage = '';

        if (errorData?.pwd) {
          combinedMessage += errorData.pwd[0]?.code || 'Password is invalid.';
        }

        combinedMessage += '  Please check and try again.';

        return thunkAPI.rejectWithValue(combinedMessage);
      }

      return thunkAPI.rejectWithValue(
        errorMessage || 'Something went wrong, please try again later.',
      );
    }
  },
);
