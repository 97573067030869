import { NavLink, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import {
  IS_MOBILE_PLATFORM,
  IS_WEB_PLATFORM,
  SCAN_FEATURE_SEQUENCE,
  SELLING_PAGE_VARIANT_FROM_SCAN,
} from 'services/constants';
import { ReactComponent as HomeIcon } from 'assets/home_icon.svg';
import { ReactComponent as DiscoverIcon } from 'assets/discover_icon.svg';
import { ReactComponent as ScanIcon } from 'assets/scan_icon.svg';
import { ReactComponent as FavoritesIcon } from 'assets/favorites_icon.svg';
import { ReactComponent as ProfileMobileIcon } from 'assets/profile_mobile_icon.svg';
import { authSelectors } from 'state-management/auth';

import s from './TabBar.module.scss';

const TabBar = () => {
  const location = useLocation();
  const wrapperRef = useRef();
  const isSubscribed = useSelector(authSelectors.selectAuthIsSubscribed);

  return (
    <div id="tab-bar" ref={wrapperRef} className={clsx(s.wrapper, { [s.web]: IS_WEB_PLATFORM })}>
      <nav>
        <ul className={s.list}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                clsx(s.link, {
                  [s.activeLinkWeb]: isActive && IS_WEB_PLATFORM,
                  [s.activeLinkMob]: isActive && IS_MOBILE_PLATFORM,
                })
              }
            >
              <HomeIcon className={s.icon} />
              <span className={s.label}>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/smart-filter"
              className={({ isActive }) =>
                clsx(s.link, {
                  [s.activeLinkWeb]: isActive && IS_WEB_PLATFORM,
                  [s.activeLinkMob]: isActive && IS_MOBILE_PLATFORM,
                })
              }
            >
              <DiscoverIcon className={s.icon} />

              <span className={s.label}>Discover</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={isSubscribed ? '/scan-a-skincare-product' : '/subscription-details'}
              state={{ needShow: SELLING_PAGE_VARIANT_FROM_SCAN, sequence: SCAN_FEATURE_SEQUENCE }}
              className={({ isActive }) =>
                clsx(s.link, {
                  [s.activeLinkWeb]:
                    isActive && location.pathname === '/scan-a-skincare-product' && IS_WEB_PLATFORM,
                  [s.activeLinkMob]:
                    isActive &&
                    location.pathname === '/scan-a-skincare-product' &&
                    IS_MOBILE_PLATFORM,
                })
              }
            >
              <ScanIcon className={s.icon} />
              <span className={s.label}>Scan</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/favorites"
              className={({ isActive }) =>
                clsx(s.link, {
                  [s.activeLinkWeb]: isActive && IS_WEB_PLATFORM,
                  [s.activeLinkMob]: isActive && IS_MOBILE_PLATFORM,
                })
              }
            >
              <FavoritesIcon className={s.icon} />
              <span className={s.label}>Favorites</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings"
              className={({ isActive }) =>
                clsx(s.link, {
                  [s.activeLinkWeb]: isActive && IS_WEB_PLATFORM,
                  [s.activeLinkMob]: isActive && IS_MOBILE_PLATFORM,
                })
              }
            >
              <ProfileMobileIcon className={s.icon} />
              <span className={s.label}>Profile</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TabBar;
